<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import axios from 'axios';
/**
 * Email-read component
 */
export default {
  setup() {

  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Détails de réponse",
      items: [
        {
          text: "Alertes",
          href: "/listAlert"
        },
        {
          text: "Détails réponse",
          active: true
        }
      ],
      pertinence: "",
      citizen: null,
      alerte: null,
      alerteReply: null,
      messages: [],
      audios: [],
      videos: [],
      files: [],
      photos: [],
      coordinates: [],
      position: {},
      reqError: null,
      submitted: false,
      map: null,
      userPosition: {},
      markers: [],
      form: {
        message: "",
      },
      username: "Steven Franklin",
    };
  },

  created() {
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    getAlertDetails() {
      const that = this;
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/alert/alert-spontaneous/findbyid/',
        data: id,
      })
        .then(
          function (response) {
            that.getAlertUpdates();
            that.alert = response.data;
            that.pertinence = that.alert.alerts[0].pertinence;
            console.log("perosition 0");
            that.userPosition = {
              lat: that.alert.alerts[0].sender_current_position.coordinates[0],
              lng: that.alert.alerts[0].sender_current_position.coordinates[1]
            };
            console.log(that.userPosition);
            /*that.alert.coordinates.forEach((item, index) => {
              const newPosition = {position :{lat: item.coordinate.coordinates[0], lng: item.coordinate.coordinates[1], title: `Position ${index + 1}`}}
              console.log(newPosition);
              that.markers = [...that.markers, newPosition];
            })*/
            if (that.pertinence == 'PERTINENT') {
              that.variant = 'primary';
            } else if (that.pertinence == 'NON_PERTINENT') {
              that.variant = 'danger';
            } else if (that.pertinence == 'DECISIVE') {
              that.variant = 'success';
            } else if (that.pertinence == 'NORMAL') {
              that.variant = 'secondary';
            }
            that.initMap();
          },
          function (error) {
            that.reqError = error;
          }
        )
    },

    CreateCenterControl(map) {
      const controlButton = document.createElement("button");
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";

      controlButton.textContent = "Centré la carte";
      controlButton.title = "Click to recenter the map";
      controlButton.type = "button";
      controlButton.addEventListener("click", () => {
        map.setCenter({ lat: this.userPosition.lat, lng: this.userPosition.lng });
      });

      return controlButton;

    },

    initMap() {
      const self = this;
      console.log("init map")

      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(self.userPosition.lat, self.userPosition.lng),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });


      new google.maps.Marker({
        position: { lat: self.userPosition.lat, lng: self.userPosition.lng },
        map: this.map,
        title: "Position Utilisateur",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          scaledSize: new google.maps.Size(50, 50),
        }
      });

      this.coordinates.forEach((item, index) => {
        new google.maps.Marker({
          position: { lat: item.coordinate.coordinates[0], lng: item.coordinate.coordinates[1] },
          map: this.map,
          title: `Position ${index + 1}`
        });

      });
      const centerControlDiv = document.createElement("div");
      // Create the control.
      const centerControl = self.CreateCenterControl(this.map);

      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

      /*this.map.addListener("click", (e) => {
        this.handleMapClick(e)
      });*/
    },

    getAlertUpdates() {
      const that = this;
      const id = parseInt(this.$route.params.id);
      this.getRequest(`/alert/alert-spontaneous/Update/?id=${id}`)
        .then(
          function (response) {
            that.updates = response.data;
            console.log(that.updates);
          },
          function (error) {
            console.log(error);
          }
        )
    },
    async setPertinence(pertinence) {
      const formData = new FormData();
      formData.append('id', parseInt(this.$route.params.id));
      formData.append('pertinence', pertinence);
      try {
        const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/alert/alert-spontaneous/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "L'Alerte à été traité!", "success");
        this.pertinence = pertinence;
        if (pertinence == 'PERTINENT') {
          this.variant = 'primary';
        } else if (pertinence == 'NON_PERTINENT') {
          this.variant = 'danger';
        } else if (pertinence == 'DECISIVE') {
          this.variant = 'success';
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors du traitement de l'alerte",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    getDetailsReponseAlerte() {
      const that = this;
      this.getRequest(`/alert/alert/reply/findbyid/?id=${this.$route.params.id}`)
        .then(
          function (response) {
            that.citizen = response.data?.citizen;
            that.alerte = response.data?.alerte;
            that.alerteReply = response.data?.alerteReply;
            console.log("user positions")
            console.log(that.alerteReply.sender_current_position?.coordinates[0])
            if (that.alerteReply.sender_current_position?.coordinates[0]) {
              that.userPosition = {
                lat: that.alerteReply.sender_current_position?.coordinates[0],
                lng: that.alerteReply.sender_current_position?.coordinates[1]
              };
            } else {
              that.userPosition = {
                lat: 6.247046,
                lng: 1.311771
              };
            }
            that.photos = response.data?.images;
            that.messages = response.data?.messages;
            that.videos = response.data?.videos;
            that.audios = response.data?.audios;
            that.files = response.data?.files;
            that.coordinates = response.data?.coordinates;
            console.log(response.data)
            that.initMap();
          },
          function (error) {
            that.reqError = error;
          }
        )
    },

    navigateToNextPage() {
      // Créer le tableau d'objets à passer
      console.log('test navigation');
      const dataToPass = {
        coordinates: this.coordinates ?? [],
        alertObject: this.alerteReply ?? [],
        alerte: this.alerte ?? []
      };
      console.log(dataToPass);
      localStorage.setItem('myData', JSON.stringify(dataToPass));

      this.$router.push({ name: 'Lancer une Interventions' });
    },


  },

  mounted() {
    this.getDetailsReponseAlerte();
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="row justify-content-end">
            <div class="col-md-6 d-flex">
              <div class="btn-toolbar p-3" role="toolbar">
                <button type="button" class="btn btn-primary me-2" @click="navigateToNextPage()">
                  <i class="mdi mdi-run"></i>
                  Déclencher intervention
                </button>
              </div>
              <b-dropdown split :text="pertinence" :variant="variant" class="p-3">
                <b-dropdown-item @click="setPertinence('PERTINENT')">Pertinent</b-dropdown-item>
                <b-dropdown-item @click="setPertinence('NON_PERTINENT')">Non pertinent</b-dropdown-item>
                <b-dropdown-item @click="setPertinence('DECISIVE')">Décisif</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="d-flex mb-4">
                <img :src="`https://api.alcit.sims-technologie.com${citizen?.picture}`" alt
                  class="avatar-md rounded-circle img-thumbnail" />
                <div class="flex-grow-1">
                  <h5 class="font-size-13 mt-1">{{ citizen?.name }} </h5>
                  <small class="text-muted">{{ citizen?.first_name }} </small>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Téléphone:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ citizen?.telephone }}</strong></h5>
                </div>
              </div>


              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Email:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ citizen?.email }}</strong></h5>
                </div>
              </div>

              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Titre alerte:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alerte?.title }}</strong></h5>
                </div>
              </div>

              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Description alerte:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alerte?.description }}</strong></h5>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Thématique:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alerte?.thematic?.name }}</strong></h5>
                </div>
              </div>

              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Date:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alerteReply?.created_at?.split('T')[0] }}</strong></h5>
                </div>
              </div>


              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">heure:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13"> {{ alerteReply?.created_at?.split('T')[1]?.split('.')[0] }}</strong>
                  </h5>
                </div>
              </div>




            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="form-group row">
      <div class="col-lg-12 d-flex flex-column">
        <div class="mb-3">
          <div class="card">
            <div class="card-body">
              <div v-if="messages?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-0 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);">
                        <strong>Messages </strong>
                      </a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-0" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row" v-viewer>
                        <div class="col-xl-4 col-6" v-for="(message, index) in messages" :key=index>
                          <div class="card">
                            <li>{{ message.message }}</li>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-else>
                Pas de message
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="card">
            <div class="card-body">
              <div v-if="photos?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);">
                        <strong>Images </strong>
                      </a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row" v-viewer>
                        <div class="col-xl-4 col-6" v-for="(photo, index) in photos" :key=index>
                          <div class="card">
                            <img class="card-img-top img-fluid"
                              :src="`https://api.alcit.sims-technologie.com${photo.image}`" alt="Card image cap" />
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-else>
                Pas d'image
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="card">
            <div class="card-body">
              <div v-if="audios?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);">
                        <strong>Audios </strong>
                      </a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="audio-player">
                        <div class="audio-item" v-for="(audio, index) in audios" :key="index">
                          <audio :src="`https://api.alcit.sims-technologie.com${audio.audio}`" controls></audio>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-else>
                Pas de fichier audio
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="card">
            <div class="card-body">
              <div v-if="videos?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-3 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);">
                        <strong>Vidéos</strong>
                      </a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-xl-4 col-6" v-for="(video, index) in videos" :key="index">
                          <div class="card">
                            <video class="card-img-top img-fluid" controls>
                              <source :src="`https://api.alcit.sims-technologie.com${video.video}`" type="video/mp4">
                              <!-- Ajoutez d'autres formats vidéo pris en charge ici si nécessaire -->
                              Votre navigateur ne prend pas en charge la balise vidéo.
                            </video>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-else>
                Pas de vidéo
              </div>
            </div>
          </div>
        </div>


        <div>
          <div class="row">
            <div class="col-lg-12">
              <section style="height: 700px;" ref="map" class="map"></section>
              <!--          <l-map :zoom="zoom" style="height: 642.4px;" :center="center">
            <l-tile-layer :url="url" :maxZoom="maxZoom"/>
            <l-marker v-if="Object.keys(position).length == 0" :visible="true" :lat-lng="position">
              <l-icon :icon-anchor="staticAnchor">
                <img src="@/assets/images/leaflet/marker-icon.png"/>
              </l-icon>
            </l-marker>
          </l-map>-->
            </div>
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
